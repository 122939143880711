import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/opt/build/repo/www/src/templates/default-mdx-page-template.tsx";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "component-generation"
    }}>{`Component generation`}<a parentName="h1" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#component-generation"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h1>
    <p>{`Component generation offers pre-defined templates of the necessary files to kickstart development and automatically export the created components for use in the project. In this document, we will explore the process of component generation and the steps required to get started with this powerful feature.`}</p>
    <h2 {...{
      "id": "1-start-the-documentation-site-development-server"
    }}>{`1. Start the documentation site development server`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#1-start-the-documentation-site-development-server"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>{`The Paragon documentation site serves both as documentation and as a workbench to create your component within. To see your component in action, you need to run the documentation site locally.`}</p>
    <pre><code parentName="pre" {...{}}>{`npm install
npm start
`}</code></pre>
    <h2 {...{
      "id": "2-create-new-component"
    }}>{`2. Create new component`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#2-create-new-component"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>{`To create new component run`}</p>
    <pre><code parentName="pre" {...{}}>{`npm run generate-component MyComponent
`}</code></pre>
    <p>{`where `}<inlineCode parentName="p">{`MyComponent`}</inlineCode>{` is your new component's name.`}</p>
    <p>{`This will create a directory in `}<inlineCode parentName="p">{`/src/`}</inlineCode>{` that will contain templates for all necessary files to start developing the component:`}</p>
    <pre><code parentName="pre" {...{}}>{`MyComponent
├── index.jsx
├── README.md
├── MyComponent.scss
├── _variables.scss
└── MyComponent.test.jsx
`}</code></pre>
    <p>{`The script will also automatically export your component from Paragon.`}</p>
    <h2 {...{
      "id": "3-start-developing"
    }}>{`3. Start developing`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#3-start-developing"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p><inlineCode parentName="p">{`/src/MyComponent/index.jsx`}</inlineCode>{` is where your component lives, the file is created with the following template, edit it to implement your own component.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-jsx"
      }}>{`import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const MyComponent = React.forwardRef(({ className, children }, ref) => (
  <div ref={ref} className={classNames('png__MyComponent', className)}>
    {children}
  </div>
));

MyComponent.defaultProps = {
  className: undefined,
};

MyComponent.propTypes = {
  /** A class name to append to the base element. */
  className: PropTypes.string,
  /** Specifies contents of the component. */
  children: PropTypes.node.isRequired,
};

export default MyComponent;

`}</code></pre>
    <h2 {...{
      "id": "4-optional-add-styles-to-your-component"
    }}>{`4. (Optional) Add styles to your component.`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#4-optional-add-styles-to-your-component"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>{`If your component requires additional styling (which most likely is the case), edit created SCSS style sheet in your
component's directory `}<inlineCode parentName="p">{`/src/MyComponent/MyComponent.scss`}</inlineCode>{` which by default contains an empty class for your component.`}</p>
    <p>{`If you wish to use SASS variables (which is the preferred way of styling the components since values can be
easily overridden and customized by the consumers of Paragon), add them in `}<inlineCode parentName="p">{`/src/MyComponent/_variables.scss`}</inlineCode>{` (this file should contain all variables specific to your component).
This way the variables will also get automatically picked up by documentation site and displayed on your component's page.`}</p>
    <p><strong parentName="p">{`Please note that you need to follow `}<a parentName="strong" {...{
          "href": "https://github.com/openedx/paragon/blob/master/docs/decisions/0012-css-styling-conventions"
        }}>{`Paragon's CSS styling conventions`}</a>{`.`}</strong></p>
    <h2 {...{
      "id": "5-document-your-component"
    }}>{`5. Document your component`}<a parentName="h2" {...{
        "aria-hidden": "true",
        "tabIndex": -1,
        "href": "#5-document-your-component"
      }}><span parentName="a" {...{
          "className": "pgn-doc__anchor"
        }}>{`#`}</span></a></h2>
    <p>{`The documentation for your component lives in `}<inlineCode parentName="p">{`src/MyComponent/README.md`}</inlineCode>{`. The documentation site scans this directory for markdown or mdx files to create pages. By default, the file is created with following content:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-md"
      }}>{`---
title: 'MyComponent'
type: 'component'
components:
- MyComponent
status: 'New'
designStatus: 'Done'
devStatus: 'Done'
notes: |
  Something special about this component
---

Describe your component here and give usage examples.

### Basic Usage

\`\`\`jsx live
<MyComponent>
  Hello!
</MyComponent>
\`\`\`

`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      